:root {
  --text-color: #333333;
  --red-color: #ff2600;
  --gray-bg-color: #f9f9f9;
  --gray-color: #777;
  --white-color: #fff;
  --font-size: 13px;
  --font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

html {
  font-size: var(--font-size);
}

body {
  margin: 0;
  padding: 0;
  font-family: var(--font-family);
  color: var(--text-color);
}

.text-gray {
  color: var(--gray-color);
}

.text-red {
  color: var(--red-color);
}

.hover-help:hover {
  cursor: help;
}

.hover-pointer:hover {
  cursor: pointer;
}

.form-control {
  font-size: var(--font-size);
}
