.App {
}

.App__logo {
  height: 24px;
  padding-right: 1em;
}
.App__content {
  padding-top: 65px;
  padding-bottom: 2em;
}

.App__navbar {
  border-bottom: 1px solid #e7e7e7;
  height: 54px;
}

.App__navbar-brand {
  font-size: 14px;
}
