.Imputation {
  padding: 0.2rem 0.5rem;
  background-color: var(--gray-bg-color);
  border-color: var(--white-color);

  font-size: 0.75rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

/* absorb wrapper list-group-item padding to allow full height clickable area */
.Imputation__clickable {
  margin-bottom: -1rem;
  flex-grow: 1;
}

.Imputation__hours {
  display: 'inline-block';
  padding: 0 1rem;
}

.Imputation__name {
}
