.MonthYear__month {
  padding: 1em 0.25em 1em 0;
}

.MonthYear__separator {
  padding: 0 0.25em;
  font-weight: 300;
}

.MonthYear__weekOfYear {
  font-size: 100%;
}
